import { PureComponent } from 'react';

class EorzeaClock extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      lt: "--:--",
      st: "--:--",
      et: "--:--"
    };
  }
  
  formatTimestamp = (timestamp = Date.now()) => {
    const time = (timestamp / 60 / 1000) % 1440
    const h = Math.floor(time / 60).toFixed(0).padStart(2, '0')
    const m = Math.floor(time % 60).toFixed(0).padStart(2, '0')
    return h + ':' + m
  }

  getLocalTime = () => {
    const ts = Date.now() - (new Date().getTimezoneOffset() * 60 * 1000)
    return this.formatTimestamp(ts)
  }

  getServerTime = () => {
    return this.formatTimestamp()
  }

  getEorzeaTime = () => {
    return this.formatTimestamp(Date.now() * 3600 / 175)
  }
  
  componentDidMount() {
    this.timerID = setInterval(() => {
      this.setState({
        lt: this.getLocalTime(),
        st: this.getServerTime(),
        et: this.getEorzeaTime()
      });
    }, 1000 / 12);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    const { lt, st, et } = this.state;
    const { useLT, useST } = this.props;
  
    return (
      <span className='eorzea-clock'>

        {useLT && (
          <span className="eorzea-clock__lt">&#xe0d0; {lt}</span>
        )}
  
        {useST && (
          <span className="eorzea-clock__st">&#xe0d1; {st}</span>
        )}
        
        <span className="eorzea-clock__et">&#xe0d2; {et}</span>
      </span>
    );
  }
}

export default EorzeaClock;