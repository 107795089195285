import React from 'react';
import { autoPlayGif } from '../initial_state';

interface EmojiProps {
  hovered: boolean;
  emoji?: string;
  url?: string;
  staticUrl?: string;
}

export class Emoji extends React.PureComponent<EmojiProps> {
  render() {
    const { emoji, hovered, url, staticUrl } = this.props;
    const filename = (autoPlayGif || hovered) ? url : staticUrl;
    const shortCode = `:${emoji}:`;
    return (
      <img
        draggable='false'
        className='emojione custom-emoji'
        alt={shortCode}
        title={shortCode}
        src={filename}
      />
    );
  }
}
