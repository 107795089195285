import React from 'react';

import type { List } from 'immutable';
import type { Account } from 'flavours/glitch/models/account';
import Overlay from 'react-overlays/Overlay';
import { autoPlayGif } from '../initial_state';
import { Skeleton } from './skeleton';
import { Emoji } from './emoji';

interface Props {
  account?: Account;
  others?: List<Account>;
  localDomain?: string;
}

interface State {
  hovered: boolean;
  emojiElement: HTMLElement | null;
}

export class DisplayName extends React.PureComponent<Props, State> {
  private contentsNode: HTMLSpanElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      hovered: false,
      emojiElement: null,
    };
    this.handleEmojiHover = this.handleEmojiHover.bind(this);
    this.handleEmojiLeave = this.handleEmojiLeave.bind(this);
  }

  componentDidMount(): void {
    this.mountTooltipElement();
  }

  mountTooltipElement(): void {
    const container = this.contentsNode;
    if (container) {
      const customEmojis = container.querySelectorAll<HTMLElement>('span.emojimagnify');
      customEmojis.forEach((el: HTMLElement) => {
        el.addEventListener('mouseenter', () => this.handleEmojiHover(el));
        el.addEventListener('mouseleave', () => this.handleEmojiLeave());

        if (el.querySelector<HTMLImageElement>('img')?.getAttribute('title') != null) el.querySelector<HTMLImageElement>('img')?.removeAttribute('title');
      });
    }
  }

  handleMouseEnter: React.ReactEventHandler<HTMLSpanElement> = ({
    currentTarget,
  }) => {
    if (autoPlayGif) {
      return;
    }

    const emojis =
      currentTarget.querySelectorAll<HTMLImageElement>('img.custom-emoji');

    emojis.forEach((emoji) => {
      const originalSrc = emoji.getAttribute('data-original');
      if (originalSrc != null) emoji.src = originalSrc;
    });
  };

  handleMouseLeave: React.ReactEventHandler<HTMLSpanElement> = ({
    currentTarget,
  }) => {
    if (autoPlayGif) {
      return;
    }

    const emojis =
      currentTarget.querySelectorAll<HTMLImageElement>('img.custom-emoji');

    emojis.forEach((emoji) => {
      const staticSrc = emoji.getAttribute('data-static');
      if (staticSrc != null) emoji.src = staticSrc;
    });
  };

  handleEmojiHover (emojiElement: HTMLElement): void {
    this.setState({ hovered: true, emojiElement: emojiElement });
  };

  handleEmojiLeave (): void {
    this.setState({ hovered: false, emojiElement: null });
  };

  isAboveCenter (element: HTMLElement | null): boolean {
    if (!element) return false;

    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    const elementMiddle = rect.top + rect.height / 2;
    const viewportMiddle = viewportHeight / 2;

    return elementMiddle < viewportMiddle;
  };

  renderEmojiTooltip(): React.ReactNode {
    const { hovered, emojiElement } = this.state;
    if (!emojiElement) return null;

    const img = emojiElement.querySelector<HTMLImageElement>('img');
    if (!img) return null;

    const shortCode = img.getAttribute('alt') ?? undefined;
    const emojiUrl = img.getAttribute('data-original') ?? undefined;
    const emojiStatic = img.getAttribute('data-static') ?? undefined;

    return (
      <Overlay
        show={hovered}
        offset={[0, 5]}
        placement={this.isAboveCenter(img) ? 'bottom' : 'top'}
        flip
        target={emojiElement}
        popperConfig={{ strategy: 'fixed' }}
      >
        {({ props, placement }) => (
          <div className="emoji-magnify-overlay" {...props}>
            <div className={`dropdown-animation ${placement}`}>
              <div className='reactions-bar__item__users'>
                <div className='reactions-bar__item__users__emoji'>
                  <span><Emoji
                      hovered={hovered}
                      emoji={shortCode}
                      url={emojiUrl}
                      staticUrl={emojiStatic}
                    /></span>
                  <span className='reactions-bar__item__users__emoji__code'>{shortCode}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </Overlay>
    );
  }

  setContentsRef = (c: HTMLSpanElement) => {
    this.contentsNode = c;
  };

  render() {
    const { others, localDomain } = this.props;

    let displayName: React.ReactNode,
      suffix: React.ReactNode,
      account: Account | undefined;

    if (others && others.size > 0) {
      account = others.first();
    } else if (this.props.account) {
      account = this.props.account;
    }

    if (others && others.size > 1) {
      displayName = others
        .take(2)
        .map((a) => (
          <bdi key={a.get('id')}>
            <strong
              className='display-name__html'
              dangerouslySetInnerHTML={{ __html: a.get('display_name_html') }}
            />
          </bdi>
        ))
        .reduce((prev, cur) => [prev, ', ', cur]);

      if (others.size - 2 > 0) {
        suffix = `+${others.size - 2}`;
      }
    } else if (account) {
      let acct = account.get('acct');

      if (!acct.includes('@') && localDomain) {
        acct = `${acct}@${localDomain}`;
      }

      displayName = (
        <bdi>
          <strong
            className='display-name__html'
            dangerouslySetInnerHTML={{
              __html: account.get('display_name_html'),
            }}
          />
        </bdi>
      );
      suffix = <span className='display-name__account'>@{acct}</span>;
    } else {
      displayName = (
        <bdi>
          <strong className='display-name__html'>
            <Skeleton width='10ch' />
          </strong>
        </bdi>
      );
      suffix = (
        <span className='display-name__account'>
          <Skeleton width='7ch' />
        </span>
      );
    }

    return (
      <span
        ref={this.setContentsRef}
        className='display-name'
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {displayName} {suffix}
        {this.renderEmojiTooltip()}
      </span>
    );
  }
}
